const _ = require('lodash')

const requirePromise = (requireFn, packageName) => new Promise(resolve => {
    requireFn(packageName, resolve)
})

const fetchFallbackJson = (fetchFn, fallbackUrls) => new Promise((resolve, reject) => {
    let fallbackCount = 0

    const tryToFetch = error => {
        if (fallbackCount >= fallbackUrls.length) {
            reject(error)
            return
        }

        const fallbackUrl = fallbackUrls[fallbackCount++]
        fetchFn(fallbackUrl, null, 'json', resolve, tryToFetch)
    }

    tryToFetch()
})

const fetchPageJson = (
    {
        fetchFn,
        requireFn,
        getDataFixerParams,
        fixedViewModePageUrl,
        onSuccess,
        onError,
        fallbackUrls = [],
        reportFixedDataFetchStarted,
        reportFixedDataFetchEnded,
        reportFixedDataFetchFallbackStarted,
        reportFixedDataFetchFallbackEnded,
        viewMode,
        registerWixErrors,
        isPreviewNonDS = false
    }
) => {
    const requireViewModeExecutor = () => registerWixErrors().then(() => requirePromise(requireFn, 'viewer-view-mode-json'))


    const fetchFromFallback = async () => {
        reportFixedDataFetchFallbackStarted()
        try {
            const [pageJson, dataFixer, viewModeExecutor] = await Promise.all([
                fetchFallbackJson(fetchFn, fallbackUrls),
                requirePromise(requireFn, 'santa-data-fixer'),
                requireViewModeExecutor()
            ])
            const fixedPage = dataFixer.fix({
                ...getDataFixerParams(),
                pageId: _.get(pageJson, ['structure', 'id'], 'masterPage'),
                pageJson
            })
            const result = await viewModeExecutor().execute(
                {
                    pageJson: fixedPage,
                    viewMode,
                    shouldCalcMeshInServer: false
                }
            )
            reportFixedDataFetchFallbackEnded()
            onSuccess(result)
        } catch (err) {
            if (onError) {
                onError(err)
                return
            }
            throw err
        }
    }

    const onFixedDataFetchSuccess = (...args) => {
        reportFixedDataFetchEnded()
        onSuccess(...args)
    }

    if (isPreviewNonDS) {
        fetchFromFallback()
        return
    }

    reportFixedDataFetchStarted()
    fetchFn(fixedViewModePageUrl, null, 'json', onFixedDataFetchSuccess, fetchFromFallback)
}

module.exports = {
    loadPage(setPageToLoad, pageId, requestInfo) {
        setPageToLoad(pageId, requestInfo)
    },
    fetchPageJson,
    increaseVersion(version, setVersion) {
        const newVersion = version + 1
        setVersion(newVersion)
        return newVersion
    },
    pageRequestSuccess(increaseVersion, setPageData, successCallback, pageId, viewerLoaded, page) {
        if (pageId === 'masterPage' && viewerLoaded) {
            increaseVersion()
        }
        setPageData(page)
        if (successCallback) {
            successCallback(page)
        }
    },
    fetchPageForPreview: (fetchFn, pageId, viewMode, onSuccess) => {
        fetchFn(pageId, viewMode).then(onSuccess)
    }
}
